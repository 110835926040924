import React from "react";
import Layout from "../../components/UI/layout";
import Header from "../../components/UI/Header/header";
import SEO from "../../components/UI/seo";
import Help from "../../components/challenges/challengeOne/Help/help";
import VideoSection from "../../components/challenges/challengeOne/videos/videos";
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next";


const ChallengeOne = () => {
  const {t} = useTranslation();
  return(
    <Layout>
      <SEO title="Codeicus | ChallengesOne" />
      <Header
        title="Innovar rápido y sin riesgos"
        description={["Si llegaste acá seguramente es porque tu empresa no para de crecer y necesitan cada vez más de la tecnología para destacar.<br></br> Conocemos de ésto y podemos ayudarte. <br></br>Escuchá las recomendaciones que nos da Fabián Aquino, CEO de Codeicus."]}
      />
      <VideoSection/>
      <Help t={t}/>
    </Layout>
  )
}
  
  export default ChallengeOne

  
export const query = graphql`
query($language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}
`;