// extracted by mini-css-extract-plugin
export var bodyHelp = "help-module--bodyHelp--f1897";
export var btn = "help-module--btn--45955";
export var btnLottie = "help-module--btnLottie--b8630";
export var contenerHelp = "help-module--contenerHelp--6b401";
export var detail = "help-module--detail--64f91";
export var error = "help-module--error--93938";
export var fromCenter = "help-module--fromCenter--2bc65";
export var fromContacto = "help-module--fromContacto--684a0";
export var fromLeft = "help-module--fromLeft--d80a4";
export var textArea = "help-module--textArea--fcdbd";
export var textBox = "help-module--textBox--3a4c9";
export var title = "help-module--title--b013d";