import React from 'react';
import * as styles from './header.module.scss';

const HeaderSection = ({title, description}) => {

	const desc = description;
    const listItems = desc.map((descript) =>
        <div className={styles.divLayer}>
            <p dangerouslySetInnerHTML={{__html: descript }}></p>
        </div>
    );
	return (
		<section className={styles.headerSection}>
			<div className={styles.containHeader}>               
				<div className={styles.infoHeader}>                    
					<h2 
					data-sal-delay='300'
					data-sal='slide-up'
					data-sal-duration='700'
					data-sal-easing='ease-out-back'>
						{title}
					</h2>
					<span
					data-sal-delay='300'
					data-sal='slide-up'
					data-sal-duration='700'
					data-sal-easing='ease-out-back'>
						{listItems}
					</span>
				</div>
			</div>
		</section>
	);
};
export default HeaderSection;
