import React, { Component } from 'react';
import Modal from '../../../UI/Modal/modal';
import * as styles from './help.module.scss';
import Button from '../../../UI/Button/button';
import Lottie from 'react-lottie';
import Waiting from '../../../../components/Lotties/waiting.json';
import axios from 'axios';
import { Formik, Field, ErrorMessage, Form} from 'formik';

class Help extends Component {
	constructor(props) {
		super(props)
		this.state = {
			name: '',
			email:'',
			msj:'',
			showStore : false,
			btnDisabled: false,
			isOpen: false,
			title:'',
			msjModal:'',
		}

	}

	toggleModal = () => {
		console.log(!this.state.isOpen);
		this.setState({
		  isOpen: !this.state.isOpen
		});
	}

	handleChange = (event) => {
		event.preventDefault();
		this.setState({
			[event.target.name]: event.target.value,
			[event.target.email]: event.target.value,
			[event.target.msj]: event.target.value
		})
	}

	submit = (data) => {

		this.setState({btnDisabled : true})
		console.log(data);
		axios.post('https://getform.io/f/2d4e93ad-c1c7-4a3d-8d0a-c47f0b8d55ca', {
			data
		}).then(res => {
			this.setState({
				title: '¡Gracias por contactarnos!',
				msjModal: ' Muy pronto alguien de nuestro equipo comercial se comunicará con vos.'
				});
			this.toggleModal();
		}).catch(function (error) {
			alert("Ocurrio un error al intentar enviar el mensaje. intenta luego");
			
		}).finally(res => {
			this.setState({btnDisabled : false});
		});

		
	}

	render(){
		const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: Waiting,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        }

		const {t} = this.props;

		return (
			<section className={styles.bodyHelp}>
				<Modal show={this.state.isOpen}
					onClose={this.toggleModal}
					title={this.state.title}
					msj={this.state.msjModal}>
				</Modal>
				<div className={styles.contenerHelp} >
					<div className={styles.title}>
						<p 
						data-sal-delay='450'
						data-sal='slide-right'
				   		data-sal-duration='700'
				   		data-sal-easing='ease-out-back'>{["¿Te gustaría conocer mas sobre nuestro plan de migración a microservicios?"]}<br></br>{["Agendemos una reunión"]}</p>
					</div>
					<div className={styles.fromContacto}
						data-sal-delay='500'
						data-sal='zoom-in'
						data-sal-duration='700'
						data-sal-easing='ease-out-back'>
						<div className={styles.fromCenter}>
						<Formik
							initialValues={{
								name: '',
								email: '',
								msj: '',
							}}
							onSubmit={(values, formikBag ) => { 
								 this.submit(values);
								 formikBag.resetForm({values: ''});
							}}
							validate={(values) => {
								const errors = {};
								const emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
								if (!values.name || values.name.length <= 3) {
									errors.name = t("contact.help.warning.name");
								}
								if (!emailRegex.test(values.email)) {
									errors.email = t("contact.help.warning.email");
								}
								if (values.msj.length < 8) {
									errors.msj = t("contact.help.warning.message");
								}
								return errors;
							}}>
							{({ initialValues, values }) => (
							<Form>
								<Field name='name' type='text' className={styles.textBox} placeholder={t("contact.help.placeholder.name")} />
								<ErrorMessage name='name'>{(msg) => <span className={styles.error}>{msg}</span>}</ErrorMessage>
								<Field name='email' type='email' className={styles.textBox} placeholder='e-mail' />
								<ErrorMessage name='email'>{(msg) => <span className={styles.error}>{msg}</span>}</ErrorMessage>
								<Field as='textarea' name='msj' type='text' className={styles.textArea} placeholder={t("contact.help.placeholder.yourProject")} />
								<ErrorMessage name='msj'>{(msg) => <span className={styles.error}>{msg}</span>}</ErrorMessage>
								<div className={styles.btn} style={{ display: this.state.btnDisabled ? "none" : "block" }} >
									<Button type='submit' >
									{t("contact.help.send")}
									</Button>
								</div>
								<div className={styles.btnLottie}  style={{ display: this.state.btnDisabled ? "block" : "none" }} >
									<Lottie 
											options={defaultOptions} 
											height={50} 
											width={120} />
								</div>
							</Form>
							)}
							</Formik>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default Help;