// extracted by mini-css-extract-plugin
export var challengeSection = "videos-module--challengeSection--68d2e";
export var containBody = "videos-module--containBody--83c8d";
export var containCars = "videos-module--containCars--25409";
export var containProject = "videos-module--containProject--5308c";
export var containTitle = "videos-module--containTitle--12040";
export var description = "videos-module--description--7c42e";
export var detalle = "videos-module--detalle--a185e";
export var number = "videos-module--number--532bf";
export var step = "videos-module--step--2d5d3";
export var title = "videos-module--title--d1fe4";