import React from 'react';
import * as styles from './videos.module.scss';
import ReactPlayer from "react-player";
import { useTranslation } from "gatsby-plugin-react-i18next"



const VideoSection = () => {


    const {t} = useTranslation();

	return (
		<section className={styles.challengeSection} id='challengeSection'>
			<div className={styles.containProject}>
                <div className={styles.containBody}>
                    <div className={styles.containTitle}
                       data-sal-delay='400'
                       data-sal='slide-up'
                       data-sal-duration='400'
                       data-sal-easing='ease-out-back'>
                        <ReactPlayer
                            url={'https://youtu.be/gMkoVZ_pKBk'}
                            width='60%'
                            height='100%'
                            controls='true'
                        />
                    </div>
                    <br></br>
                    <br></br>
                    <div className={styles.containTitle}
                       data-sal-delay='400'
                       data-sal='slide-up'
                       data-sal-duration='400'
                       data-sal-easing='ease-out-back'>
                        <ReactPlayer
                            url={'https://youtu.be/CdTCyYww0UA'}
                            width='60%'
                            height='100%'
                            controls='true'
                        />
                    </div>
                </div>

			</div>
		</section>
	);
};
export default  VideoSection ;